import React from "react";

import Home from "../../pages/Home"
import Policy from "../../pages/Policy"
import Rules from "../../pages/Rules"

const Blog = React.lazy(() => import("../../pages/Blog/Blog"))
const BlogPost = React.lazy(() => import("../../pages/Blog/BlogPost"))

const Studios = React.lazy(() => import("../../pages/Studios/Studios"))
const StudiosPage = React.lazy(() => import("../../pages/Studios/StudiosPage"))

const Podcasts = React.lazy(() => import("../../pages/Podcasts/Podcasts"))
const PodcastPage = React.lazy(() => import("../../pages/Podcasts/PodcastPage"))

const About = React.lazy(() => import("../../pages/About/About"))

const Partner = React.lazy(() => import("../../pages/Partner/Partner"))
const PartnerAuth = React.lazy(() => import("../../pages/Partner/PartnerAuth"))

const SignIn = React.lazy(() => import("../../pages/Account/SignIn"))
const SignUp = React.lazy(() => import("../../pages/Account/SignUp"))
const StudioRegister = React.lazy(() => import("../../pages/Account/StudioRegister"))
const Account = React.lazy(() => import("../../pages/Account/Account"))

const AdminAuth = React.lazy(() => import("../../pages/_Admin/AdminAuth"))
const Admin = React.lazy(() => import("../../pages/_Admin/Admin"))

export interface IRoute {
  path: string;
  component: React.ReactNode;
  exact?: boolean;
}

export enum RouteNames {
  HOME = '/',
  POLICY = '/policy',
  RULES = '/rules',

  ABOUT = '/about',

  BLOG = '/blog',
  BLOG_PAGE = '/blog/:name',
  PODCASTS = '/podcasts',
  PODCASTS_PAGE = '/podcasts/:name',

  CATALOG = '/studios',
  CATALOG_STUDIO = "/studios/:name",

  ACCOUNT_SIGN = "/signin",
  ACCOUNT_SIGNUP = "/signup",
  STUDIO_REGISTER = "/connect",

  ACCOUNT = "/account",
  ACCOUNT_PAGE = "/account/:page",

  PARTNER_AUTH = "/partner/auth",
  PARTNER = "/partner",
  PARTNER_PAGE = "/partner/:page",
  
  ADMIN_AUTH = "/admin/auth",
  ADMIN_HOME = "/admin",
  ADMIN_STATS = "/admin/analytics",

  ADMIN_CLIENTS = "/admin/users",
  ADMIN_CLIENTS_ID = "/admin/users/:id",

  ADMIN_CATALOG = "/admin/studios",
  ADMIN_CATALOG_ID = "/admin/studios/:id",

  ADMIN_CATS = "/admin/categories",
  ADMIN_CATS_ID = "/admin/categories/:id",
  ADMIN_BLOG = "/admin/blog",
  ADMIN_BLOG_ID = "/admin/blog/:id",
  ADMIN_BRANDS = "/admin/podcasts",
  ADMIN_BRANDS_ID = "/admin/podcasts/:id",
  ADMIN_ORDERS = "/admin/orders",
  ADMIN_ORDERS_ID = "/admin/orders/:id",
  ADMIN_CITIES = "/admin/cities",
  ADMIN_CITIES_ID = "/admin/cities/:id",
  ADMIN_SHOPS = "/admin/shops",
  ADMIN_SHOPS_ID = "/admin/shops/:id",
  ADMIN_SETTINGS = "/admin/settings"
}

export const publicRoutes: IRoute[ ] = [
  { path: RouteNames.HOME, exact: true, component: <Home /> },
  { path: RouteNames.POLICY, exact: true, component: <Policy /> },
  { path: RouteNames.RULES, exact: true, component: <Rules /> },

  { path: RouteNames.ABOUT, exact: true, component: <About /> },
  { path: RouteNames.BLOG, exact: true, component: <Blog /> },
  { path: RouteNames.BLOG_PAGE, exact: true, component: <BlogPost /> },

  { path: RouteNames.PODCASTS, exact: true, component: <Podcasts /> },
  { path: RouteNames.PODCASTS_PAGE, exact: true, component: <PodcastPage /> },

  { path: RouteNames.CATALOG, exact: true, component: <Studios /> },
  { path: RouteNames.CATALOG_STUDIO, exact: true, component: <StudiosPage /> },

  { path: RouteNames.PARTNER_AUTH, exact: true, component: <PartnerAuth /> },
  { path: RouteNames.PARTNER, exact: true, component: <Partner /> },
  { path: RouteNames.PARTNER_PAGE, exact: true, component: <Partner /> },
  
  { path: RouteNames.ACCOUNT_SIGN, exact: true, component: <SignIn /> },
  { path: RouteNames.ACCOUNT_SIGNUP, exact: true, component: <SignUp /> },
  { path: RouteNames.STUDIO_REGISTER, exact: true, component: <StudioRegister /> },

  { path: RouteNames.ACCOUNT, exact: true, component: <Account /> },
  { path: RouteNames.ACCOUNT_PAGE, exact: true, component: <Account /> },

  { path: RouteNames.ADMIN_AUTH, exact: true, component: <AdminAuth /> },
  { path: RouteNames.ADMIN_HOME, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_STATS, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_CLIENTS, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_CLIENTS_ID, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_CATALOG, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_CATALOG_ID, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_CATS, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_CATS_ID, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_BLOG, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_BLOG_ID, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_BRANDS, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_BRANDS_ID, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_ORDERS, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_ORDERS_ID, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_CITIES, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_CITIES_ID, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_SHOPS, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_SHOPS_ID, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_SETTINGS, exact: true, component: <Admin /> }
]
