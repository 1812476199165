import { observer } from "mobx-react-lite"
import { FC, useContext, useEffect, useRef, useState } from "react"
import { Context } from ".."

import useWindowDimensions from "../core/hooks/useWindowDimensions"
import { useFetching } from "../core/hooks/useFetching"

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import {isElementInViewport} from "../core/utils/utils"

import styles from "../styles/Home.module.scss"
import Breadcrumbs from "./_shared/Breadcrumbs/Breadcrumbs"
import CardWrap from "./_shared/CardWrap/CardWrap"
import {ApiService} from "../core/api/api"
import {useLocation} from "react-router-dom"
import {XProduct} from "../core/models/store/product"
import RubbleIcon from "../components/icons/RubbleIcon"

interface IArticle {
  id?: number
  image: string | null
  frame?: string
  title: string
  subtitle: string
  text: string
  source: string | null
  link: string | null
}

const ArticlesData: IArticle[] = []

const ShopsData: any[] = []

const Home: FC = () => {

  const { store, shopStore, studioStore } = useContext(Context)
  const { width } = useWindowDimensions()

  const location = useLocation()
  
  const swiperEl1 = useRef(null as any)
  const swiperEl2 = useRef(null as any)
  const swiperEl3 = useRef(null as any)

  const [swiper1, setSwiper1] = useState({} as any)
  const [swiper2, setSwiper2] = useState({} as any)
  const [swiper3, setSwiper3] = useState({} as any)

  const [userName, setUserName] = useState("")
  const [userCity, setUserCity] = useState("")
  const [userPhone, setUserPhone] = useState("")

  const [userToken, setUserToken] = useState("")
  
  const [clusterPoints, setClusterPoints] = useState([
    [55.686852, 37.852365], [47.232634, 39.625379], [45.119326, 38.927496], [45.097692, 38.973570]
  ] as number[][])

  const [articles, setArticles] = useState([] as IArticle[])

  const [fetchData, isDataLoading, errorData] = useFetching(async () => {
    let lst: IArticle[] = []

    lst = ArticlesData

    setArticles(prev => prev = lst)
  })
  
  const [fetchStudios] = useFetching(async () => {
    const res = await ApiService.studios.getStudios({
      city_id: studioStore.selectedCity == null ? 0 : studioStore.selectedCity.id,
      page: studioStore.pageIndex,
      limit: studioStore.pageLimit,
      search: studioStore.search,
      category: studioStore.category,
      order: studioStore.order,
      order_by: studioStore.orderBy,
      status: studioStore.status
    })

    studioStore.setTotalStudios(res.data.total)
    studioStore.setStudios(res.data.studios)
  })

  const runAnimations = () => {
    
  }

  const openForm = (title: string) => {
    store.setFormTitle(title)
    store.setIsFormVisible(true)
  }

  const runOpacity = (el: HTMLElement | null, delay: number = 0, asc: number = 0) => {
    if (el != null) {
      // if (width <= 960) {
      //   asc = -40;
      // }

      if (el.style.opacity == "") {
        el.style.opacity = `0`;
      }

      if (isElementInViewport(el)) {
        let dell = window.innerHeight - ((el.getBoundingClientRect().y + el.getBoundingClientRect().height) - delay + asc);
        let spl = dell / 100;  
        
        if (el.style.opacity == "") {
          el.style.opacity = `${spl}`;
        } else {
          
          el.style.opacity = `${spl}`;
        }
      } else {
        if (parseInt(el.style.opacity) == 0) {
          el.style.opacity = `0`;
        }
      }
    }
  }

  const runBottomOpacity = (el: HTMLElement | null, delay: number = 0, asc: number = 0) => {
    if (el != null) {
      // if (width <= 960) {
      //   delay = 40;
      // }

      if (el.style.transform == "") {
        el.style.transform = "translateY(35px)";
      }

      if (el.style.opacity == "") {
        el.style.opacity = "0";
      }

      if (isElementInViewport(el)) {
        let dell = window.innerHeight - ((el.getBoundingClientRect().y + el.getBoundingClientRect().height) - delay + asc);
        let spl = (dell) / 100;
        if (spl > parseInt(el.style.opacity)) {
        }
        el.style.opacity = `${spl}`;
        
        let ix = ((dell) / 4) - 35;

        if (ix > parseInt(el.style.bottom.replace("px", ""))) {
          
        }
        if (ix < 0) {
          el.style.transform = `translateY(${-ix}px)`;
        } else {
          el.style.transform = `translateY(0px)`;
        }
      } else {
        if (el.style.opacity == "0") {
          el.style.opacity = `0`;
          el.style.transform = "translateY(35px)";
        }
      }
    }
  }
  
  const getFinalPrice = (product: XProduct) => {
    if (product.compound == "item") {
      return product.price
    } else {
      return product.price / 10
    }
  }
  
  useEffect(() => {

    fetchData()
    fetchStudios()

    setTimeout(() => {
      runAnimations()
    }, 400)

    const onScroll = (event: any) => {
      const scrollTop = event.target.scrollTop


    }

    console.log(location.hash)
    if (location.hash != "") {
      setTimeout(() => {
        const hsh = document.querySelector(location.hash)
        if (hsh != null) {
          hsh.scrollIntoView({
            behavior: 'smooth',
            // block: 'start'
          })
        }
      }, 300)
    }

    document.getElementById("root")!.addEventListener('scroll', onScroll)

    return () => {
      document.getElementById("root")!.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <>
      <div className="main_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12">
              HOME
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(Home)