import { FC } from "react"
import useWindowDimensions from "../../core/hooks/useWindowDimensions"

interface XTitleProps {
  children: any
  size?: string
  top?: number
  bottom?: number
}

const XTitle: FC<XTitleProps> = ({
  children, size = "default", top = 20, bottom = 16
}) => {
  const { width } = useWindowDimensions()
  return (
    <>
      <h1 style={{
        fontFamily: "Farm",
        fontSize: size == "default" ? (width > 960 ? "78px" : "48px") : (size == "small" ? "48px" : "32px"),
        fontWeight: "700",
        lineHeight: "1",
        marginTop: `${top}px`,
        marginBottom: `${width > 960 ? bottom : bottom / 2}px`,
        color: "#000"
      }}>{children}</h1>
    </>
  )
}

export default XTitle