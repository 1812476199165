import { observer } from "mobx-react-lite"
import { FC, useContext, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { Context } from "../../.."

import styles from "./Breadcrumbs.module.scss"

export interface BreadcrumbsProps {

}

export interface BreadcrumbsPath {
  path: string
  title: string
}

const BreadPaths: BreadcrumbsPath[] = [
  {path: "", title: "Главная"},
  {path: "studios", title: "Креативные студии"},
  {path: "basket", title: "Корзина"},
  {path: "orders", title: "Заказы"},
  {path: "account", title: "Аккаунт"},
  {path: "signin", title: "Авторизация"},
  {path: "favorites", title: "Избранное"},
  {path: "blog", title: "Блог"},
  {path: "podcasts", title: "Подкасты"},
  {path: "rules", title: "Правила"},
  {path: "partner", title: "Личный кабинет партнера"},
  {path: "connect", title: "Подать заявку"},
  {path: "about", title: "О нас"},
  {path: "delivery", title: "Доставка"}
]

const Breadcrumbs: FC<BreadcrumbsProps> = ({}) => {
  
  const { pagesStore } = useContext(Context)
  
  const location = useLocation()

  const [breadcrumbs, setBreadcrumbs] = useState([] as any[])

  const parseBreads = () => {
    let newBreads: any[] = []

    let paths: string[] = location.pathname.split("/")

    if (paths.length > 0 && paths[1] != "") {
      paths.forEach((pth, ix) => {
        const isBlogPath = paths.includes("blog") && pth != "blog"
        const isBrandPath = paths.includes("brands") && pth != "brands"

        if (ix != 0) {
          if (pth != "" && pth != "/") {
            newBreads.push({ url: `/${pth}`, name: isBlogPath ? (pagesStore.selectedPost != null ? pagesStore.selectedPost.title : "") : (isBrandPath ? (pagesStore.selectedBrand != null ? pagesStore.selectedBrand.name : "") : getBreadName(pth)) })
          }
        } else {
          newBreads.push({ url: `/${pth}`, name: getBreadName(pth) })
        }
      })
    } else {
      newBreads.push({url: `/`, name: "Главная"})
    }

    console.log(newBreads)

    setBreadcrumbs((prev: any) => prev = newBreads)
  }

  const getBreadName = (path: string) => {
    const exists = BreadPaths.find(v => v.path == path)
    if (exists != undefined) {
      return exists.title
    } else {
      return ""
    }
  }

  useEffect(() => {
    setTimeout(() => {
      parseBreads()
    }, 300)
  }, [location])

  return (
    <>
      <div className={styles.breadcrumbs}>
        {breadcrumbs.map((bread, ix) => <a href={ix == breadcrumbs.length - 1 ? `${window.location.host}${breadcrumbs.map(v => v.url.replace("/","")).join("/")}` : bread.url}>{bread.name}</a>)}
      </div>
    </>
  )
}

export default observer(Breadcrumbs)