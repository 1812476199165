import { observer } from "mobx-react-lite"
import { FC } from "react"
import useWindowDimensions from "../../../core/hooks/useWindowDimensions"

import styles from "./CardWrap.module.scss"

export interface CardWrapProps {
  children: any
}

const CardWrap: FC<CardWrapProps> = ({
  children
}) => {

  const { width } = useWindowDimensions()

  return (
    <>
      <div className={width > 960 ? "container" : ""}>
        <div className={width > 960 ? "row" : ""}>
          <div className={width > 960 ? "col-12" : ""}>
            <div className={styles.card_wrap} id="cardwrap">
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(CardWrap)