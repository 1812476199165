import { observer } from "mobx-react-lite"
import { FC, useContext, useEffect, useRef, useState } from "react"
import ImageSlider from "../components/ImageSlider"
import { Context } from ".."

import styles from "../styles/Policy.module.scss"
import useWindowDimensions from "../core/hooks/useWindowDimensions"
import CardWrap from "./_shared/CardWrap/CardWrap"
import XTitle from "./_shared/XTitle"
import Breadcrumbs from "./_shared/Breadcrumbs/Breadcrumbs"

const Rules: FC = () => {

  const { store, adminStore } = useContext(Context)
  const { width } = useWindowDimensions()

  useEffect(() => {
    return () => {
      
    }
  }, [])

  return (
    <>
      <CardWrap>
        <Breadcrumbs />
        <XTitle>Правила сервиса</XTitle>
      </CardWrap>
    </>
  )
}

export default observer(Rules)