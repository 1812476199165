import { makeAutoObservable } from "mobx";
import { XPost, XPostTag } from "../models/blog/post";
import {XBrand} from "../models/store/brand";

export default class PagesStore {

  blogList: XPost[] = []
  blogTags: any[] = []

  blogTotal: number = 0
  blogPage: number = 0
  blogSearch: string = ""
  blogTag: string = ""

  selectedPost: XPost | null = null

  brands: XBrand[] = []
  selectedBrand: XBrand | null = null

  searchGoVisible: boolean = false
  brandsSearchText: string = ""

  constructor() {
    makeAutoObservable(this)
  }

  setBrandsSearchText(val: string) {
    this.brandsSearchText = val
  }

  setSearchGoVisible(val: boolean) {
    this.searchGoVisible = val
  }

  setBlogTotal(val: number) {
    this.blogTotal = val
  }

  setBlogPage(val: number) {
    this.blogPage = val
  }

  setBlogSearch(val: string) {
    this.blogSearch = val
  }

  setBlogTag(val: string) {
    this.blogTag = val
  }

  setBlogTags(val: any) {
    this.blogTags = val
  }

  setBlogList(val: any[]) {
    this.blogList = val
  }

  setBrands(val: XBrand[]) {
    this.brands = val
  }

  setSelectedPost(val: XPost | null) {
    this.selectedPost = val
  }

  setSelectedBrand(val: XBrand | null) {
    this.selectedBrand = val
  }

}