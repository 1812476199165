import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { API_URL, StorageKeys } from "../utils/defaults";
import { isNullOrUndefined } from "../utils/utils";

const $api = axios.create({
  baseURL: API_URL
});

$api.interceptors.request.use((config: AxiosRequestConfig) => {
  config.headers!["Authorization"] = `Bearer ${localStorage.getItem(StorageKeys.TOKEN)}`;
  return config;
});

$api.interceptors.response.use((response: AxiosResponse) => {
  return response;
}, async (error: any) => {
  console.log("INTERCEPT", error.response.data.message);

  if (!isNullOrUndefined(error.response.data.newToken)) {
    localStorage.setItem(StorageKeys.TOKEN, error.response.data.newToken)
    window.location.reload()
  } else if (!isNullOrUndefined(error.response.data.reject)) {
    localStorage.clear()
    window.location.reload()
  }

  return error
});

export default $api