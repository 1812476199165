import { makeAutoObservable } from "mobx";
import {StorageKeys} from "../utils/defaults";

export default class StudioStore {

  cities: any[] = []
  selectedCity: any | null = null
  selectedCityName: string = ""
  
  isCityVisible: boolean = false
  cityQuestShown: boolean = false
  cityQuestVisible: boolean = false

  pageIndex: number = 0
  pageLimit: number = 20
  search: string = ""
  category: string = "all"
  order: string = "DESC"
  orderBy: string = "id"
  status: string = "all"

  studios: any[] = []
  totalStudios: number = 0

  selectedStudio: any | null = null

  orders: any[] = []

  podcasts: any[] = []
  articles: any[] = []

  constructor() {
    makeAutoObservable(this)
  }

  setPodcasts(val: any) {
    this.podcasts = val
  }

  setArticles(val: any) {
    this.articles = val
  }

  setOrders(val: any[]) {
    this.orders = val
  }

  setStudios(val: any[]) {
    this.studios = val
  }

  setSelectedStudio(val: any) {
    this.selectedStudio = val
  }

  setTotalStudios(val: number) {
    this.totalStudios = val
  }

  setSearch(val: string) {
    this.search = val
  }

  setCategory(val: string) {
    this.category = val
  }

  setOrder(val: string) {
    this.order = val
  }

  setOrderBy(val: string) {
    this.orderBy = val
  }

  setStatus(val: string) {
    this.status = val
  }

  setPageLimit(val: number) {
    this.pageLimit = val
  }

  setPageIndex(val: number) {
    this.pageIndex = val
  }

  setCities(val: any[]) {
    this.cities = val
  }

  setSelectedCityName(val: string) {
    this.selectedCityName = val
  }

  setSelectedCity(val: any) {
    this.selectedCity = val
  }

  setIsCityVisible(val: boolean) {
    this.isCityVisible = val
  }

  openCityQuest() {
    const already = localStorage.getItem(StorageKeys.CITY_QUEST_SHOWN)
    if (already != null) {
      return
    }
    this.cityQuestShown = true
    setTimeout(() => {
      this.cityQuestVisible = true
    }, 100)
  }

  closeCityQuest() {
    localStorage.setItem(StorageKeys.CITY_QUEST_SHOWN, "true")
    this.cityQuestVisible = false
    setTimeout(() => {
      this.cityQuestShown = false
    }, 300)
  }

  setCityQuestVisible(val: boolean) {
    this.cityQuestVisible = val
  }

  setCityQuestShown(val: boolean) {
    this.cityQuestShown = val
  }

}