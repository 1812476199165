import moment from "moment";
import 'moment/locale/ru'
import { useMemo } from "react"

export const getDateFormat = (date: string, format: string = "DD MMM YYYY, HH:mm") => {
  moment.lang("ru");
  moment.locale("ru");
  return moment(date).format(format);
}

export const getDateTSFormat = (ts: number, format: string = "DD MMM YYYY, HH:mm") => {
  return moment(ts).format(format);
}

export const prevent = (ev: any) => {
  ev.preventDefault()
  ev.stopPropagation()
}

export function Utf8ArrayToStr(array: any) {
  var out, i, len, c;
  var char2, char3;

  out = "";
  len = array.length;
  i = 0;
  while(i < len) {
  c = array[i++];
  switch(c >> 4)
  { 
    case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7:
      // 0xxxxxxx
      out += String.fromCharCode(c);
      break;
    case 12: case 13:
      // 110x xxxx   10xx xxxx
      char2 = array[i++];
      out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
      break;
    case 14:
      // 1110 xxxx  10xx xxxx  10xx xxxx
      char2 = array[i++];
      char3 = array[i++];
      out += String.fromCharCode(((c & 0x0F) << 12) |
                     ((char2 & 0x3F) << 6) |
                     ((char3 & 0x3F) << 0));
      break;
  }
  }

  return out;
}


export const fixNumber = (num: number) => {
  if (num < 10) {
    return `0${num}`;
  } else {
    return `${num}`;
  }
}

export const buildParams = (map: any) => {
  let query = "";
  for (let key in map) {
    if (query == "") {
      query = `?${key}=${map[key]}`;
    } else {
      query += `&${key}=${map[key]}`;
    }
  }
  return query;
}

export function urlify(text: string) {
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, function(url) {
    return '<a href="' + url + '" target="_blank">' + url + '</a>';
  })
}

export function isNullOrUndefined<T>(obj: T | null | undefined): obj is null | undefined {
  return typeof obj === "undefined" || obj === null;
}

export async function asyncForEach(array: any[], callback: any) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export function declOfNum(n: number, text_forms: string[]) {  
  n = Math.abs(n) % 100; 
  var n1 = n % 10;
  if (n > 10 && n < 20) { return text_forms[2]; }
  if (n1 > 1 && n1 < 5) { return text_forms[1]; }
  if (n1 == 1) { return text_forms[0]; }
  return text_forms[2];
}

export function translit(word: string) {
	let answer: string = '';
	const converter: any = {
		'а': 'a',    'б': 'b',    'в': 'v',    'г': 'g',    'д': 'd',
		'е': 'e',    'ё': 'e',    'ж': 'zh',   'з': 'z',    'и': 'i',
		'й': 'y',    'к': 'k',    'л': 'l',    'м': 'm',    'н': 'n',
		'о': 'o',    'п': 'p',    'р': 'r',    'с': 's',    'т': 't',
		'у': 'u',    'ф': 'f',    'х': 'h',    'ц': 'c',    'ч': 'ch',
		'ш': 'sh',   'щ': 'sch',  'ь': '',     'ы': 'y',    'ъ': '',
		'э': 'e',    'ю': 'yu',   'я': 'ya',
 
		'А': 'A',    'Б': 'B',    'В': 'V',    'Г': 'G',    'Д': 'D',
		'Е': 'E',    'Ё': 'E',    'Ж': 'Zh',   'З': 'Z',    'И': 'I',
		'Й': 'Y',    'К': 'K',    'Л': 'L',    'М': 'M',    'Н': 'N',
		'О': 'O',    'П': 'P',    'Р': 'R',    'С': 'S',    'Т': 'T',
		'У': 'U',    'Ф': 'F',    'Х': 'H',    'Ц': 'C',    'Ч': 'Ch',
		'Ш': 'Sh',   'Щ': 'Sch',  'Ь': '',     'Ы': 'Y',    'Ъ': '',
		'Э': 'E',    'Ю': 'Yu',   'Я': 'Ya',   ' ': '-',    '&': '-',
    '«': '',     '»': '', '': ''
	};
 
	for (let i = 0; i < word.length; ++i ) {
		if (converter[word[i]] == undefined) {
			answer += word[i]
		} else {
			answer += converter[word[i]]
		}
	}
 
	return answer
}

export function sortById(a: any, b: any) {
  if (a.id > b.id) {
    return 1
  } else if (a.id < b.id) {
    return -1
  } else {
    return 0
  }
}

export function sortByIndex(a: any, b: any) {
  if (a.index > b.index) {
    return 1
  } else if (a.index < b.index) {
    return -1
  } else {
    return 0
  }
}

export function viewportToPixels(value: string) {
  let parts: any = value.match(/([0-9\.]+)(vh|vw)/)
  let q = Number(parts[1])
  let side: number = window['innerHeight']
  return side * (q/100)
}

export function stripPhone(phone: string): string {
  return phone.replaceAll(" ", "").replaceAll("-", "").replaceAll("(", "").replaceAll(")", "")
}

export function isElementInViewport(el?: any | null): boolean {
  if (el == undefined || el == null) {
    return false;
  }
  
  var top = el.offsetTop;
  var left = el.offsetLeft;
  var width = el.offsetWidth;
  var height = el.offsetHeight;

  while(el!.offsetParent) {
    el = el!.offsetParent;
    top += el.offsetTop;
    left += el.offsetLeft;
  }

  const cont = document.getElementById("root")

  if (cont != null) {
    // console.log(top, (cont.scrollTop + cont.clientHeight))
  }
  
  return cont != null ? (
    (top + 20) < (cont.scrollTop + cont.clientHeight) &&
    left < (cont.scrollLeft + cont.scrollWidth)
  ) : false
}
